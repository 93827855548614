mat-paginator {
  font-size: 12px;
  background: transparent;

  .mat-paginator-container {
    margin: 0;
    padding: 0;
    min-height: 45px;
    justify-content: flex-start;
  }

  .mat-paginator-page-size {
    display: none !important;
  }

  // Paginator Buttons
  span.mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // Navigation First
  .mat-paginator-navigation-first {
    order: 1;

    .mat-paginator-icon path {
      fill: #1A1A1A;
    }

    &:disabled .mat-paginator-icon path {
      fill: #D0D0D0;
    }
  }

  // Navigation Previous
  .mat-paginator-navigation-previous {
    order: 2;

    .mat-paginator-icon path {
      fill: #1A1A1A;
    }

    &:disabled .mat-paginator-icon path {
      fill: #D0D0D0;
    }
  }

  // Range Label
  .mat-paginator-range-label {
    width: 100px;
    color: #414141;
    order: 3;
    text-align: center;
  }

  // Navigation Next
  .mat-paginator-navigation-next {
    order: 4;

    .mat-paginator-icon path {
      fill: #1A1A1A;
    }

    &:disabled .mat-paginator-icon path {
      fill: #D0D0D0;
    }
  }

  // Navigation Last
  .mat-paginator-navigation-last {
    order: 5;

    .mat-paginator-icon path {
      fill: #1A1A1A;
    }

    &:disabled .mat-paginator-icon path {
      fill: #D0D0D0;
    }
  }
}
