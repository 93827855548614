/* You can add global styles to this file, and also import other style files */

@import 'bootstrap/scss/bootstrap';
@import 'material-icons/iconfont/material-icons.css';

@import 'src/css-customized/window.scss';
@import 'src/css-customized/mat-paginator.scss';

.w-60px {
  width: 60px;
}

.w-120px {
  width: 120px;
}
