@font-face {
  font-family: OpenSans;
  src: url(../assets/fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: bold;
  src: url(../assets/fonts/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-style: italic;
  src: url(../assets/fonts/OpenSans-Italic.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: bold;
  font-style: italic;
  src: url(../assets/fonts/OpenSans-BoldItalic.ttf) format('truetype');
}

* {
  font-family: OpenSans, Arial;
}

html, body {
  margin: 0;
  padding: 0;
  font-size: 12px;
  min-height: 100vh;
}

body {
  background-color: #fff;
}
